<template>
<div>
  <b-container>
  <h4 class="text-graph">Temperatura {{title}}</h4>
    <LineChart v-if="loaded" :chartdata="chartdata" :options="options" class="Line" ref="Loo"/>
  </b-container>
</div>
</template>

<script>
import LineChart from "./Chart.vue";

export default {
  name: "LineChartContainer",
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata: null,
    chartdata2: null,
    type: 'line',
    options: {
        legend:{
          display: false
        }
    },
    title: "na piętrze"
  }),
  async mounted() {
    this.loaded = false;
    try {
      const response = await fetch('./chart.php?id=C2');
      const data = await response.json();
      this.chartdata = data[0];
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
    methods: {
      fillData(id,title) {
        this.title = title
        fetch('./chart.php?id='+id).
        then(response => response.json()).
        then(data =>data[0]).
        then(data => this.$refs.Loo.renderChart(data, this.options))
      }
    }
};
</script>
<style lang="sass">
canvas
    margin: 0 auto
.Line
  padding: 15px 0 0 0
.text-graph
  padding: 10px
  color: white
</style>