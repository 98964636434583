<template>
<header>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col b-col col lg="2"><h1>Home</h1><h3>Temperatura:</h3></b-col>
      <b-col cols="12" md="auto">
        <VueSlickCarousel v-bind="settings" style="height: 20vh;" class="row no-gutters align-center justify-center carousel-wrapper" v-if="temps && temps.length > 1">
          <div v-for="item in temps" v-bind:key="item.id">
            <b-card 
              :title="item.title"
              tag="article"
              class="mb-2"
              :id="item.id"
              @click="openGraph(item.id,item.title)"
              >
                  <font-awesome-icon :icon="['fas', item.icon]" class="icon alt"/>
                <b-card-text>
                  Temperatura: <b>{{item.temp}}°C</b>
                </b-card-text>
            </b-card>
          </div>
        </VueSlickCarousel>
        <b-container class="boxs-desktop">
          <b-row>
                    <b-col v-for="item in temps" v-bind:key="item.id">
            <b-card
              :title="item.title"
              tag="article"
              class="mb-2"
              :id="item.id"
              @click="openGraph(item.id,item.title)"
              >
                  <font-awesome-icon :icon="['fas', item.icon]" class="icon alt"/>
                <b-card-text>
                  Temperatura: <b>{{item.temp}}°C</b>
                </b-card-text>
            </b-card>
          </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</header>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots

import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
 
    export default {
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
          "centerMode": true,
          "focusOnSelect": true,
          "dots": false,
          "infinite": true,
          "swipeToSlide": true,
          "speed": 500,
          "slidesToShow": 2,
          "slidesToScroll": 1,
          "initialSlide": 1,
          "responsive": [
            {
              "breakpoint": 991,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 1,
                "initialSlide": 1
              }
            },
            {
              "breakpoint": 767,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
              }
            },
            {
              "breakpoint": 375,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
              }
            }
          ]
        },
        temps: null
      }
    },  
    created() {
          fetch('./info.php').then(response => response.json()).then(data => this.temps = data)
        }, 
    methods: {
      openGraph(id,title){
        this.$emit('openClicked',id,title)
      },
    },
  }
  
</script>
<style lang="sass">
  header
    color: white
    padding: 10px
    padding-top: 30px
  .card-body
    color: black
  nav
    background-color: #212529
  .card
    height: 170px
    width: 230px
  #C1
    background-color: #bd4f6c
    background-image: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%)
  #C2
    background-color: #eec0c6
    background-image: linear-gradient(315deg, #eec0c6 0%, #e58c8a 74%)
  #C3
    background-color: #20bf55
    background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%)
  #C4
    background-color: #bdd4e7
    background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)
  @media(max-width: 1200px)
    .boxs-desktop
      display: none!important
  @media(min-width: 1200px)
    .carousel-wrapper
      display: none!important
  .icon
    font-size: 150px
    color: rgba(0, 0, 0, 0.3)
    position: absolute 
    bottom: 85px
    left: 115px
    transform: translate(-50%, 50%)
    
  
</style>
