<template>
  <div id="app">
    <navbar 
    @openClicked="DEB"/>
    <graph ref="foo"/>
  </div>
</template>

<script>
import navbar from './components/navbar.vue'
import graph from './components/graph.vue'
export default {
  name: 'App',
  components: {
    navbar,graph
  },
  methods: {
    DEB(id,title){
        this.$refs.foo.fillData(id,title);
      },
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="sass">
body
  margin: 0
  background-color: #212529
  .my-widget
    color: var(--danger)
</style>