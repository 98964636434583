import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudSun,faHome } from "@fortawesome/free-solid-svg-icons";

//import {
//  faStackOverflow,
//  faGithub
//} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import './registerServiceWorker'

library.add(faCloudSun, faHome);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
